import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    let tokenString = localStorage.getItem('token') || '';
    let userToken = null;

    if (tokenString !== '') {
      try {
        userToken = JSON.parse(tokenString);
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }

    return userToken?.token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {

    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  const returnToken = t => {
    return token
  }

  return {
    setToken: saveToken,
    token,
    getToken: returnToken
  }
}
