import { useState, useEffect } from "react";
import useFetch from "./useFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function AddHabitForm(props) {
  const [habitName, setHabitName] = useState("");
  const [habitType, setHabitType] = useState("number"); // Default habit type is number
  const [habits, setHabits] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);
  const api = useFetch(props.token);

  useEffect(() => {
    fetchHabits();
  }, []);

  async function fetchHabits() {
    try {
      const response = await api.get("habits/");
      setHabits(response);
    } catch (error) {
      console.error("Error fetching habits:", error);
    }
  }

  async function deleteHabit(habitId) {
    if (window.confirm("Are you sure you want to delete this habit?")) {
      try {
        await api.remove(`habits/${habitId}`);
        fetchHabits(); // Refresh the list of habits after deletion
      } catch (error) {
        console.error("Error deleting habit:", error);
      }
  }
}

  async function handleCreateHabit(event) {
    event.preventDefault();

    const body = {
      habit_name: habitName,
      value_type: mapHabitType(habitType),
    };

    try {
      await api.post("habits/", body);
      // Handle successful habit creation (e.g., show a success message)
      // Refresh the list of habits
      fetchHabits();
      setSuccessMessage(true)
      setFailedMessage(false)
    } catch (error) {
      console.error("Error creating habit:", error);
      setFailedMessage(true)
      setSuccessMessage(false)
      // Handle error (e.g., show an error message)
    }

    // Reset form fields
    setHabitName("");
    setHabitType("number");
  }

  function mapHabitType(valueType) {
    switch (valueType) {
      case "number":
        return "float";
      case "text":
        return "char";
      case "true/false":
        return "bool";
      default:
        return "float";
    }
  }

  return (
    <div>
      <h2>Your Habits:</h2>
        {!habits ? (
          <p>Loading habits...</p>
        ) : habits.length === 0 ? (
          <p>No habits added yet. Add some below!</p>
        ) : (
        <ul>
          {habits.map((habit) => (
            <li key={habit.id}>
              {habit.habit_name}
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => deleteHabit(habit.id)}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              />
            </li>
          ))}
        </ul>
      )}
      <h2>New Habit: </h2>
      <form onSubmit={handleCreateHabit}>
        <label>
          Habit Name:
          <input
            type="text"
            value={habitName}
            onChange={(event) => setHabitName(event.target.value)}
          />
        </label>
        <br />

        <label>
          Habit Type:
          <select
            value={habitType}
            onChange={(event) => setHabitType(event.target.value)}
          >
            <option value="number">Number</option>
            <option value="text">Text</option>
            <option value="true/false">True/False</option>
          </select>
        </label>
        <br />

        <button type="submit">Create Habit</button>
        {successMessage && <p>Habit created!</p>}
        {failedMessage && <p> Something went wrong - try again!</p>}
      </form>
    </div>

  );
}
