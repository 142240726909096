import useFetch from "./useFetch";
import { useState, useEffect } from "react";

export default function EntryForm(props) {
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [habits, setHabits] = useState([]);
  const [habitValues, setHabitValues] = useState({});
  const api = useFetch(props.token);

  useEffect(() => {
    fetchCustomizedHabits();
  }, []);

  async function fetchCustomizedHabits() {
    try {
      const response = await api.get("habits/");
      setHabits(response);
    } catch (error) {
      console.error("Error fetching customized habits:", error);
    }
  }

  function handleInputChange(event) {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setHabitValues((prevValues) => ({
        ...prevValues,
        [name]: checked,
      }));
    } else {
      setHabitValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const entries = Object.entries(habitValues).map(([habitId, value]) => {
      habitId=parseInt(habitId)
      const habit = habits.find((h) => h.id === habitId);

      return {
        date: date,
        habit: habitId,
        value_char: habit.value_type === "char" ? value : null,
        value_float: habit.value_type === "float" ? parseFloat(value) : null,
        value_bool: habit.value_type === "bool" ? value : null,
      };
    });

    try {
      await api.post("entries/", entries);

      setDate(new Date().toISOString().slice(0, 10));
      setHabitValues({});
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  }

  return (
    <div>
      {!habits ? (
        <p>Loading habits...</p>
      ) : habits.length === 0 ? (
        <p>No habits added yet. Go to the "habit" tab and add some!</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>
            Date:
            <input
              type="date"
              name="date"
              value={date}
              onChange={(event) => setDate(event.target.value)}
            />
          </label>
          <br />

          {habits.map((habit) => (
            <div key={habit.id}>
              <label className="form-label">
                <label className="label-text">{habit.habit_name}:</label>
                {habit.value_type === "bool" ? (
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    name={habit.id}
                    checked={habitValues[habit.id]}
                    onChange={handleInputChange}
                  />
                ) : habit.value_type === "float" ? (
                  <input
                    type="number"
                    name={habit.id}
                    value={habitValues[habit.id] || ""}
                    onChange={handleInputChange}
                  />
                ) : (
                  <input
                    type={habit.value_type}
                    name={habit.id}
                    value={habitValues[habit.id] || ""}
                    onChange={handleInputChange}
                  />
                )}
              </label>
              <br />
            </div>
          ))}

          <button type="submit">Submit</button>
      </form>)}
    </div>
  );
}