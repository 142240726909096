import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useFetch from "./useFetch";

export default function RoutineDetail(props){
    const params = useParams()
    const [details, setDetails] = useState({})
    const api = useFetch(props.token)
    useEffect(() => {
        api.get("routines/" + params.id + "/")
            .then(data => setDetails(data))
            .catch(error => console.log(error))
    }, [params.id])
    return (
        <div className="Routine">
            Date: {details.date} <br />
            Bed Time: {details.bed_time}<br />
            Wake Time: {details.awake_time}<br />
            Last Coffee: {details.last_coffee}<br />
            Number of Coffees: {details.number_of_coffees}<br />
            Alcohol Cut Off: {details.last_alcohol}<br />
            Number of Alcoholic Beverages: {details.alcoholic_beverages}<br />
            Milligrams of glycine: {details.glycine}<br />
            Milligrams of Ashwagandha: {details.ashwagandha}<br />
            Wind-down stretching: {details.stretching === "True" ? "Yes" : "No"}<br />
            Caloric Surplus: {details.caloric_surplus === "True" ? "Yes" : "No"}<br />
        </div>
    );
}
