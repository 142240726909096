import React, {useState} from 'react';
import useFetchNoUser from "./useFetchNoUser";
import {Link, useNavigate} from "react-router-dom";

export default function ForgotPassword({setToken}) {
  const [email, setEmail] = useState();
  const api = useFetchNoUser()
  const navigate = useNavigate();

  function handleSubmit(event){
    event.preventDefault();
    const body = {"email": email}
    api.post("accounts/password/reset/", body)
        .then(data => {
          setToken(data.access_token)
          navigate('/');
        })
        .catch(error => console.log(error))

  }

  return(
    <div className="login-wrapper">
      <h1>Password Reset</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Email:</p>
          <input type="text" onChange={e => setEmail(e.target.value)}/>
        </label>
        <div>
          <button type="submit">Reset</button>
        </div>
      </form>
      <Link to={"/login"}>Back to login</Link>
    </div>
  )
}
