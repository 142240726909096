import React, { useState } from 'react';
import useFetchNoUser from "./useFetchNoUser";
import { Link, useNavigate } from "react-router-dom";

export default function Login({ setToken, setUser }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);  // State for error messages
  const api = useFetchNoUser();
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);  // Clear any existing errors
    const body = { "username": username, "password": password };

    api.post("accounts/login/", body)
      .then(data => {
        if (data.access_token) {
          setToken(data.access_token);
          setUser(data.user);  // Assuming the backend sends user info
          navigate('/integrations');
        } else {
          setError(data.non_field_errors || "Login failed. Please try again.");
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setError(error.response.data.non_field_errors || "An error occurred. Please try again.");
        } else {
          setError("An unexpected error occurred. Please try again later.");
        }
      });
  }

  return (
    <div className="login-wrapper">
      <h1>FitForYou</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input
            type="text"
            value={username}
            onChange={e => setUserName(e.target.value)}
            required
          />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
      {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
      <Link to={"/forgot"}>Forgot Password?</Link><br />
      Need an account? <Link to={"/signup"}>Sign up</Link>
    </div>
  );
}
