import React, { useState } from 'react';
import useFetchNoUser from './useFetchNoUser';
import {Link, useNavigate} from "react-router-dom";

export default function Signup() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const navigate = useNavigate();
  const api = useFetchNoUser();

  function handleSubmit(event) {

    event.preventDefault();
    const body = { username, email, password1, password2 };
    api
      .post('registration/', body)
      .then(data => {
        if (data.username || data.password1 || data.password2 || data.email || data.non_field_errors){
          alert(JSON.stringify(data))
        }
        else {
          navigate('/login');
        }
      })
      .catch(error => console.log(error));
  }

  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword1(event.target.value);
  }

  function handleConfirmPasswordChange(event) {
    setPassword2(event.target.value);
  }

  function isPasswordMatch() {
    return password1 === password2;
  }

  return (
    <div className="signup-wrapper">
      <h1>Sign Up</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" value={username} onChange={handleUsernameChange} />
        </label>
        <label>
          <p>Email</p>
          <input type="email" value={email} onChange={handleEmailChange} />
        </label>
        <label>
          <p>Password</p>
          <input type="password" value={password1} onChange={handlePasswordChange} />
        </label>
        <label>
          <p>Confirm Password</p>
          <input type="password" value={password2} onChange={handleConfirmPasswordChange} />
          {!isPasswordMatch() && <p style={{"color": "red", "font-size":12}}>Passwords do not match</p>}
        </label>
        <div>
          <button type="submit" disabled={!isPasswordMatch()}>
            Sign Up
          </button>
        </div>
      </form>
        <Link to={"/login"}>Login</Link>
    </div>
  );
}