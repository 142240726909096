import {useEffect, useState} from "react";
import useFetch from "./useFetch";
import Routine from "./Routine";

export default function Routines(props){
    const [routines, setRoutines] = useState([])
    const api = useFetch(props.token)
    useEffect(() => {
        api.get("routines/")
            .then(data => {
                setRoutines(data)
            })
            .catch(error => console.log(error))
    }, [])

    const ulStyle = {
        listStyleType: "None"
    }
    return (
        <>
            <ul style={ulStyle}>
                {routines.map(routine => <li key={routine.id}><Routine details={routine} token={props.token}></Routine> <br/> </li>)}
            </ul>
        </>
    );
}
