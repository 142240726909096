import Oura from "./Oura"
import {useEffect, useState} from "react";
import useFetch from "./useFetch";
import Privacy from "./Privacy";

export default function Integrations(props){
  const api = useFetch(props.token)
  const [oura, setOura] = useState(null)
  useEffect(() => {
    api.get(`user/integrations/`)
      .then(data => {
        setOura(data.oura);
      })
      .catch(error => console.log(error))
  }, []);

  const integrations = [
    {
      name: "Oura",
      logo: "https://static.ouraring.com/images/oura-developer-logo-black.svg",
      element: <Oura token={props.token} authenticated={oura}/>,
    },
    {
      name: "Garmin",
      logo:
        "https://companieslogo.com/img/orig/GRMN-acdf71ee.png?t=1649180734",
      element: <p style={{"color": "maroon"}}>Coming soon!</p>
    },
    {
      name: "Whoop",
      logo:
        "https://avatars.githubusercontent.com/u/5738134?s=280&v=4",
      element:  <p style={{"color": "maroon"}}>Coming soon!</p>
    },
  ];

  return (
    <>
    <table className="integration-table">
      <thead>
        <tr>
          <th>Application</th>
          <th>    </th>
          <th>Integrated?</th>
        </tr>
      </thead>
      <tbody>
        {integrations.map((integration) => (
          <tr key={integration.name}>
            <td>{integration.name}</td>
            <td>
              <img src={integration.logo} alt={`${integration.name} logo`}
                style={{ width: "50px", height: "50px" }}
              />
            </td>
            <td style={{margin: "center"}}>
              {integration.element}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <Privacy />
    </>
  );
};
