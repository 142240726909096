import { NavLink } from "react-router-dom";
import useFetch from "./useFetch";

export default function Navbar({setToken}) {
  const api = useFetch()
  function handleLogout(event){
    event.preventDefault();
    api.post("accounts/logout/", {})
        .then(data => {
          setToken(null)
        })
        .catch(error => console.log(error))
  }

  return (
    <nav className="navbar">
      <NavLink to="/" className="nav-brand">
        FitForYou
      </NavLink>
      <ul>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? "active" : ""} to="/">
            Correlations
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? "active" : ""} to="/advanced">
            Advanced
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? "active" : ""} to="/integrations">
            Integrations
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? "active" : ""} to="/entry">
            Entry
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? "active" : ""} to="/habit">
            Add a habit
          </NavLink>
        </li>
        {/*<li className="nav-item">*/}
        {/*  <NavLink className={({isActive}) => isActive ? "active" : ""} to="/coffeeform">*/}
        {/*    New Coffee*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        <li className={"nav-item"}>
          <button onClick={handleLogout}>Logout</button>
        </li>
      </ul>
    </nav>
  );
}