import {useState} from "react";


export default function useFetch(token) {
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL ||  "http://127.0.0.1:8090/";

  function get(url) {
    let headers = {
            "Content-Type": "application/json",
    }
    if (token){
        headers["Authorization"] = "Bearer " + token
    }
    return new Promise((resolve, reject) => {
      fetch(baseUrl + url, {
          method: 'GET',
          headers: headers
        })
        .then(response =>{
          if (response.status === 403) {
           window.location.href = "/"; // redirect to login page
           throw new Error("Unauthorized");
          }
          return response.json()
        })
        .then(data => {
          if (!data) {
            setLoading(false);
            return reject(data);
          }
          setLoading(false);
          resolve(data);
        })
        .catch(error => {
          setLoading(false);

          reject(error);

        });
    });
  }

  function post(url, body) {
    let headers = {
            "Content-Type": "application/json",
    }
    if (token){
        headers["Authorization"] = "Bearer " + token
    }

    return new Promise((resolve, reject) => {
      fetch(baseUrl + url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(body)
      })
        .then(response =>{
          if (response.status === 403) {
           window.location.href = "/"; // redirect to login page
           throw new Error("Unauthorized");
          }
          return response.json()
        })
        .then(data => {
          if (!data) {
            setLoading(false);
            return reject(data);
          }
          setLoading(false);
          resolve(data);
        })
        .catch(error => {
          setLoading(false);
          reject(error);
        });
    });
  }

  function remove(url) {
      let headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers["Authorization"] = "Bearer " + token;
      }

      return new Promise((resolve, reject) => {
        fetch(baseUrl + url, {
          method: "delete",
          headers: headers,
        })
          .then((response) => {
            if (response.status === 403) {
              window.location.href = "/"; // redirect to login page
              throw new Error("Unauthorized");
            }
            return response;
          })
          .then((data) => {
            if (!data) {
              setLoading(false);
              return reject(data);
            }
            setLoading(false);
            resolve(data);
          })
          .catch((error) => {
            setLoading(false);
            reject(error);
          });
      });
    }

  return { get, post, remove, loading };
};
