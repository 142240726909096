import useFetch from "./useFetch";
import {useEffect, useState} from "react";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, ResponsiveContainer} from 'recharts';


export default function Home(props){
    const [options, setOptions] = useState({})
    const [correlations, setCorrelations] = useState({})
    const [metric, setmetric] = useState()
    const [correlationStyle, setCorrelationStyle] = useState({})

    const api = useFetch(props.token)

    useEffect(() => {
        api.get("correlations/options/")
            .then(data => setOptions(data))
            .catch(error => console.log(error))
    },[])

    function handleMetricSelection(event){
        setmetric(event.target.value)

    }
    function handleSubmitButton(event) {
        const url = `correlations/?field_to_measure=${metric}`
        api.get(url)
            .then(data => {
                setCorrelations(data)
            })
            .catch(error => console.log(error))
    }


    const renderMiniCharts = () => {
      const data = Object.entries(correlations).map(([key, effect]) => ({ name: key, effect }));

      return (
        <ResponsiveContainer width="95%" height="100%">
          <BarChart data={data} layout="vertical" margin={{ top: 20, left: 30, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
              dataKey="name"
              type="category"
              label={{ value: '', position: 'insideRight' }}
              interval={0}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="effect">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.effect >= 0 ? 'green' : 'red'} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    };

    return (
    <>
      <select onChange={handleMetricSelection} value={metric}>
        <option value="">Metric</option>
        {Object.entries(options).map(([section, values]) => (
          <optgroup key={section} label={section}>
            {values.map((value) => (
              <option key={value} value={value}>{value.split(".")[1].replace("_", " ")}</option>
            ))}
          </optgroup>
        ))}
      </select>
        <br />
      <button type="submit" onClick={handleSubmitButton}>Correlate!</button> <br />
      {metric != null ?
            <div className="chart-container">{renderMiniCharts()}</div>
          :
          <p className="correlation-instruction">Choose a metric to see the effect breakdown!</p>
      }
    </>
    )
}