
import './App.css';
import Routines from "./Routines"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DayForm from "./DayForm";
import Navbar from "./Navbar";
import RoutineDetail from "./RoutineDetail";
import Login from "./Login";
import useToken from "./useToken";
import {TokenContext} from "./TokenContext";
import Home from "./Home";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import {useState} from "react";
import Integrations from "./Integrations";
import IntegrationSucess from "./IntegrationSucess";
import EntryForm from "./EntryForm";
import AddHabitForm from "./AddHabitForm";
import Privacy from "./Privacy";
import Correlation from "./Correlation";


function App() {
  const { token, setToken } = useToken();
  const [user, setUser] = useState(0)

  document.title = "FitForYou"
  if(!token) {
    return (
        <BrowserRouter>
          <Routes>

            <Route path="/login" element={<Login setToken={setToken} setUser={setUser}/>}></Route>
            <Route path="/signup" element={<Signup/>}></Route>
            <Route path="/forgot" element={<ForgotPassword/>}></Route>
            <Route path="/success" element={<IntegrationSucess/>}></Route>
            <Route path="/*" element={<Login setToken={setToken} />}></Route>
          </Routes>
        </BrowserRouter>
         )
  }

  return (
    <BrowserRouter>
      <TokenContext.Provider value={token}>
          <Navbar setToken={setToken}/>
          <div className="container">
            <Routes>
                <Route path="/login" element={<Login setToken={setToken} setUser={setUser}/>}></Route>
                <Route path="/" element={<Home token={token} user={user}/>}></Route>
                <Route path="/advanced" element={<Correlation token={token} user={user}/>}></Route>
                <Route path="/dayform" element={<DayForm token={token}/>}>
                </Route>
                <Route path="/integrations" element={<Integrations token={token}/>}></Route>
                <Route path="/habit" element={<AddHabitForm token={token}/>}></Route>
                <Route path="/entry" element={<EntryForm token={token}/>}></Route>
                <Route path="/routines" element={<Routines token={token}/>}></Route>
                <Route path="/routines/:id" element={<RoutineDetail token={token}/>}></Route>
            </Routes>
          </div>
      </TokenContext.Provider>
    </BrowserRouter>
  );
}

export default App;
