import useFetch from "./useFetch";
import {useState} from "react";


export default function DayForm(props){
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [numberOfCoffees, setNumberOfCoffees] = useState(0);
    const [lastCoffee, setLastCoffee] = useState(null);
    const [useScreensBeforeBed, setUseScreensBeforeBed] = useState(false);
    const [sleepSupplements, setSleepSupplements] = useState(false);
    const [alcoholicBeverages, setAlcoholicBeverages] = useState(0);
    const [lastAlcohol, setLastAlcohol] = useState(null);
    const api = useFetch(props.token)

    function handle_submit(event){
        const body = {
            "date": date,
            "number_of_coffees": numberOfCoffees,
            "last_coffee": lastCoffee,
            "use_screens_before_bed": useScreensBeforeBed,
            "sleep_supplements": sleepSupplements,
            "alcoholic_beverages": alcoholicBeverages,
            "last_alcohol": lastAlcohol
        }

        api.post("routines/", body )

        setDate(new Date().toISOString().slice(0, 10));
        setNumberOfCoffees(0);
        setLastCoffee(null);
        setUseScreensBeforeBed(false);
        setSleepSupplements(false);
        setAlcoholicBeverages(0);
        setLastAlcohol(null);
        event.preventDefault();
    }
    return (
        <form onSubmit={handle_submit}>
            <label>
              Date:
              <input
                type={"date"}
                name="date"
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
          </label><br />
          <label>
            Number of coffees:
            <input
              type={"number"}
              name="numberOfCoffees"
              value={numberOfCoffees}
              onChange={(event) => setNumberOfCoffees(event.target.value)}
            />
          </label><br />
            {numberOfCoffees !== 0 && <label>
            When did you stop drinking coffee?
            <input
              type={"time"}
              name="lastCoffee"
              value={lastCoffee}
              onChange={(event) => setLastCoffee(event.target.value)}
            />
          </label>}
          <label>
            Did you use screens before bed?
            <input
              type={"checkbox"}
              name="screensBeforeBed"
              value={useScreensBeforeBed}
              onChange={(event) => setUseScreensBeforeBed(event.target.value)}
            />
          </label><br />
          <label>
            Alcoholic beverages:
            <input
              type={"number"}
              name="alcoholicBeverages"
              value={alcoholicBeverages}
              onChange={(event) => setAlcoholicBeverages(event.target.value)}
            />
          </label><br />
        {(alcoholicBeverages !== 0 || alcoholicBeverages === null) &&
          <label>
            When did you stop drinking alcohol?
            <input
              type={"time"}
              name="lastAlcohol"
              value={lastAlcohol}
              onChange={(event) => setLastAlcohol(event.target.value)}
            />
          </label>
        }
          <label>
            Did you use take sleep supplements?
            <input
              type={"checkbox"}
              name="sleepSupplements"
              value={sleepSupplements}
              onChange={(event) => setSleepSupplements(event.target.value)}
            />
          </label><br />
          <button type="submit">Submit</button>
        </form>
    );
}
