import {useState} from "react";
import useFetch from "./useFetch";
import OAuth2Login from 'react-simple-oauth2-login';

export default function Oura(props) {
  const [authenticated, setAuthenticated] = useState(false);
  const [upToDate, setUpToDate] = useState(false);
  const [needsRelogin, setNeedsRelogin] = useState(false);  // New state to handle re-login
  const baseUrl = process.env.CLIENT_URL || "https://app.fitforyou.me/"; //"http://localhost:3000/"

  const api = useFetch(props.token);

  const onSuccess = response => {
    api.get(`ouralogin/callback/?code=${response.code}`)
      .then(data => {
        setAuthenticated(true); // Set authenticated to true on successful login
        setNeedsRelogin(false); // Reset the re-login state to remove the message
      })
      .catch(error => {
        if (error.status === 500 || error.message === 're-authenticate') { // Assuming 're-authenticate' is a custom error sent by the backend
          setNeedsRelogin(true);
        } else {
          console.error(error);
        }
      });
  };

  const onFailure = response => {
    console.error(response);
  };

  function handleRefreshButton() {
      api.get("oura/refresh/")
        .then(data => setUpToDate(true))
        .catch(error => {
          console.error(error);
          //setNeedsRelogin(true);
          if (error.response && error.response.status === 403) {
            // Set re-login if the error status is 403 Unauthorized
            setNeedsRelogin(true);
          }
        });
  }

  if (needsRelogin) {
    return (
      <div>
        <p style={{ color: "red" }}>Session expired. Please log in again.</p>
        <OAuth2Login
          authorizationUrl="https://cloud.ouraring.com/oauth/authorize"
          responseType="code"
          clientId="7CEXNDO46QXKR56H"
          redirectUri={baseUrl}
          onSuccess={onSuccess}
          onFailure={onFailure}
          scope={"daily workout tag"}
        >Login to Oura</OAuth2Login>
      </div>
    );
  }

  if (upToDate) {
    return <div style={{ color: "green" }}>Up to date!</div>;
  }

  if ((authenticated || props.authenticated) && !upToDate) {
    return <button style={{ color: "green" }} onClick={handleRefreshButton}>Refresh Oura data</button>;
  }

  return (
    <OAuth2Login
      authorizationUrl="https://cloud.ouraring.com/oauth/authorize"
      responseType="code"
      clientId="7CEXNDO46QXKR56H"
      redirectUri={baseUrl}
      onSuccess={onSuccess}
      onFailure={onFailure}
      scope={"daily workout tag"}
    >Login to Oura</OAuth2Login>
  );
}